.clr-picker {
  z-index: 1000;
  direction: ltr;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border-radius: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 200px;
  display: none;
  position: absolute;
  box-shadow: 0 0 5px #0000000d, 0 5px 20px #0000001a;
}

.clr-picker.clr-open, .clr-picker[data-inline="true"] {
  display: flex;
}

.clr-picker[data-inline="true"] {
  position: relative;
}

.clr-gradient {
  cursor: pointer;
  background-image: linear-gradient(#0000, #000), linear-gradient(90deg, #fff, currentColor);
  border-radius: 3px 3px 0 0;
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
  position: relative;
}

.clr-marker {
  cursor: pointer;
  background-color: currentColor;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: -6px 0 0 -6px;
  position: absolute;
}

.clr-picker input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 16px;
}

.clr-picker input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
}

.clr-picker input[type="range"]::-moz-range-track {
  border: 0;
  width: 100%;
  height: 16px;
}

.clr-picker input[type="range"]::-moz-range-thumb {
  border: 0;
  width: 16px;
  height: 16px;
}

.clr-hue {
  background-image: linear-gradient(to right, red 0, #ff0 16.66%, #0f0 33.33%, #0ff 50%, #00f 66.66%, #f0f 83.33%, red 100%);
}

.clr-alpha, .clr-hue {
  border-radius: 4px;
  width: calc(100% - 40px);
  height: 8px;
  margin: 5px 20px;
  position: relative;
}

.clr-alpha span {
  border-radius: inherit;
  background-image: linear-gradient(90deg, #0000, currentColor);
  width: 100%;
  height: 100%;
  display: block;
}

.clr-alpha input, .clr-hue input {
  opacity: 0;
  cursor: pointer;
  appearance: none;
  background-color: #0000;
  width: calc(100% + 32px);
  height: 16px;
  margin: 0;
  position: absolute;
  top: -4px;
  left: -16px;
}

.clr-alpha div, .clr-hue div {
  pointer-events: none;
  background-color: currentColor;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-left: -8px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  box-shadow: 0 0 1px #888;
}

.clr-alpha div:before {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.clr-format {
  order: 1;
  width: calc(100% - 40px);
  margin: 0 20px 20px;
  display: none;
}

.clr-segmented {
  box-sizing: border-box;
  color: #999;
  border: 1px solid #ddd;
  border-radius: 15px;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 12px;
  display: flex;
  position: relative;
}

.clr-segmented input, .clr-segmented legend {
  opacity: 0;
  pointer-events: none;
  border: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.clr-segmented label {
  font-size: inherit;
  font-weight: 400;
  line-height: initial;
  text-align: center;
  cursor: pointer;
  flex-grow: 1;
  margin: 0;
  padding: 4px 0;
}

.clr-segmented label:first-of-type {
  border-radius: 10px 0 0 10px;
}

.clr-segmented label:last-of-type {
  border-radius: 0 10px 10px 0;
}

.clr-segmented input:checked + label {
  color: #fff;
  background-color: #666;
}

.clr-swatches {
  order: 2;
  width: calc(100% - 32px);
  margin: 0 16px;
}

.clr-swatches div {
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 12px;
  display: flex;
}

.clr-swatches button {
  color: inherit;
  text-indent: -1000px;
  white-space: nowrap;
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 4px 6px;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.clr-swatches button:after {
  content: "";
  border-radius: inherit;
  background-color: currentColor;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 0 1px #0000001a;
}

input.clr-color {
  color: #444;
  text-align: center;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 16px;
  order: 1;
  width: calc(100% - 80px);
  height: 32px;
  margin: 15px 20px 20px auto;
  padding: 0 10px;
  font-family: sans-serif;
  font-size: 14px;
}

input.clr-color:focus {
  border: 1px solid #1e90ff;
  outline: 0;
}

.clr-clear, .clr-close {
  color: #fff;
  cursor: pointer;
  background-color: #666;
  border: 0;
  border-radius: 12px;
  order: 2;
  height: 24px;
  margin: 0 20px 20px;
  padding: 0 20px;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  display: none;
}

.clr-close {
  margin: 0 20px 20px auto;
  display: block;
}

.clr-preview {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 15px 0 20px 20px;
  position: relative;
  overflow: hidden;
}

.clr-preview:after, .clr-preview:before {
  content: "";
  border: 1px solid #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.clr-preview:after {
  background-color: currentColor;
  border: 0;
  box-shadow: inset 0 0 0 1px #0000001a;
}

.clr-preview button {
  z-index: 1;
  outline-offset: -2px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.clr-alpha div, .clr-color, .clr-hue div, .clr-marker {
  box-sizing: border-box;
}

.clr-field {
  color: #0000;
  display: inline-block;
  position: relative;
}

.clr-field input {
  direction: ltr;
  margin: 0;
}

.clr-field.clr-rtl input {
  text-align: right;
}

.clr-field button {
  color: inherit;
  text-indent: -1000px;
  white-space: nowrap;
  pointer-events: none;
  border: 0;
  width: 30px;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
  overflow: hidden;
  transform: translateY(-50%);
}

.clr-field.clr-rtl button {
  left: 0;
  right: auto;
}

.clr-field button:after {
  content: "";
  background-color: currentColor;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 1px #00000080;
}

.clr-alpha, .clr-alpha div, .clr-field button, .clr-preview:before, .clr-swatches button {
  background-image: repeating-linear-gradient(45deg, #aaa 25%, #0000 25% 75%, #aaa 75%, #aaa), repeating-linear-gradient(45deg, #aaa 25%, #fff 25% 75%, #aaa 75%, #aaa);
  background-position: 0 0, 4px 4px;
  background-size: 8px 8px;
}

.clr-marker:focus {
  outline: 0;
}

.clr-keyboard-nav .clr-alpha input:focus + div, .clr-keyboard-nav .clr-hue input:focus + div, .clr-keyboard-nav .clr-marker:focus, .clr-keyboard-nav .clr-segmented input:focus + label {
  outline: 0;
  box-shadow: 0 0 0 2px #1e90ff, 0 0 2px 2px #fff;
}

.clr-picker[data-alpha="false"] .clr-alpha {
  display: none;
}

.clr-picker[data-minimal="true"] {
  padding-top: 16px;
}

.clr-picker[data-minimal="true"] .clr-alpha, .clr-picker[data-minimal="true"] .clr-color, .clr-picker[data-minimal="true"] .clr-gradient, .clr-picker[data-minimal="true"] .clr-hue, .clr-picker[data-minimal="true"] .clr-preview {
  display: none;
}

.clr-dark {
  background-color: #444;
}

.clr-dark .clr-segmented {
  border-color: #777;
}

.clr-dark .clr-swatches button:after {
  box-shadow: inset 0 0 0 1px #ffffff4d;
}

.clr-dark input.clr-color {
  color: #fff;
  background-color: #555;
  border-color: #777;
}

.clr-dark input.clr-color:focus {
  border-color: #1e90ff;
}

.clr-dark .clr-preview:after {
  box-shadow: inset 0 0 0 1px #ffffff80;
}

.clr-dark .clr-alpha, .clr-dark .clr-alpha div, .clr-dark .clr-preview:before, .clr-dark .clr-swatches button {
  background-image: repeating-linear-gradient(45deg, #666 25%, #0000 25% 75%, #888 75%, #888), repeating-linear-gradient(45deg, #888 25%, #444 25% 75%, #888 75%, #888);
}

.clr-picker.clr-polaroid {
  border-radius: 6px;
  box-shadow: 0 0 5px #0000001a, 0 5px 30px #0003;
}

.clr-picker.clr-polaroid:before {
  content: "";
  box-sizing: border-box;
  color: #fff;
  filter: drop-shadow(0 -4px 3px #0000001a);
  pointer-events: none;
  border: 8px solid #0000;
  border-top-width: 0;
  border-bottom: 10px solid;
  width: 16px;
  height: 10px;
  display: block;
  position: absolute;
  top: -10px;
  left: 20px;
}

.clr-picker.clr-polaroid.clr-dark:before {
  color: #444;
}

.clr-picker.clr-polaroid.clr-left:before {
  left: auto;
  right: 20px;
}

.clr-picker.clr-polaroid.clr-top:before {
  top: auto;
  bottom: -10px;
  transform: rotateZ(180deg);
}

.clr-polaroid .clr-gradient {
  border-radius: 3px;
  width: calc(100% - 20px);
  height: 120px;
  margin: 10px;
}

.clr-polaroid .clr-alpha, .clr-polaroid .clr-hue {
  border-radius: 5px;
  width: calc(100% - 30px);
  height: 10px;
  margin: 6px 15px;
}

.clr-polaroid .clr-alpha div, .clr-polaroid .clr-hue div {
  box-shadow: 0 0 5px #0003;
}

.clr-polaroid .clr-format {
  width: calc(100% - 20px);
  margin: 0 10px 15px;
}

.clr-polaroid .clr-swatches {
  width: calc(100% - 12px);
  margin: 0 6px;
}

.clr-polaroid .clr-swatches div {
  padding-bottom: 10px;
}

.clr-polaroid .clr-swatches button {
  width: 22px;
  height: 22px;
}

.clr-polaroid input.clr-color {
  width: calc(100% - 60px);
  margin: 10px 10px 15px auto;
}

.clr-polaroid .clr-clear {
  margin: 0 10px 15px;
}

.clr-polaroid .clr-close {
  margin: 0 10px 15px auto;
}

.clr-polaroid .clr-preview {
  margin: 10px 0 15px 10px;
}

.clr-picker.clr-large {
  width: 275px;
}

.clr-large .clr-gradient {
  height: 150px;
}

.clr-large .clr-swatches button {
  width: 22px;
  height: 22px;
}

.clr-picker.clr-pill {
  box-sizing: border-box;
  width: 380px;
  padding-left: 180px;
}

.clr-pill .clr-gradient {
  border-radius: 3px 0 0 3px;
  width: 180px;
  height: 100%;
  margin-bottom: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.clr-pill .clr-hue {
  margin-top: 20px;
}

/*# sourceMappingURL=index.ae959378.css.map */
